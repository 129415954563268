import * as Types from '../../../__generated__/graphql';

import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ProzessUebersichtQueryVariables = Types.Exact<{
  groupId?: Types.Maybe<string>;
  search?: Types.Maybe<string>;
}>;

export type ProzessUebersichtQuery = {
  __typename?: 'Query';
  my:
    | {
        __typename?: 'BackofficeUser';
        id: string;
        kommune: {
          __typename?: 'Kommune';
          prozesseUndLeistungen: Array<UserFacingProzess>;
          prozessGroups: Array<{
            __typename?: 'ProzessGroup';
            id: string;
            name: string;
          }>;
        };
      }
    | {__typename?: 'Buerger'; id: string}
    | {__typename?: 'SystemUser'; id: string};
};

export type UserFacingProzess =
  | UserFacingProzess_Prozess
  | UserFacingProzess_ServicePortalLink;

export type UserFacingProzess_Prozess = {
  __typename?: 'Prozess';
  versionenCount: number;
  id: string;
  name: string;
  tags: Array<{__typename?: 'ProzessTag'; tag: string}>;
  versionen: Array<{__typename?: 'ProzessVersion'; id: string}>;
  currentVersion?: {
    __typename?: 'ProzessVersion';
    id: string;
    publishedAt?: Date | null;
  } | null;
  group: ProzessGroup | null;
};

export type UserFacingProzess_ServicePortalLink = {
  __typename?: 'ServicePortalLink';
  url: string;
  id: string;
  name: string;
  group: ProzessGroup | null;
};

export type ProzessGroup = {
  __typename?: 'ProzessGroup';
  id: string;
  name: string;
};

export type SetGroupToProzessMutationVariables = Types.Exact<{
  groupId?: Types.Maybe<string>;
  prozessId: string;
}>;

export type SetGroupToProzessMutation = {
  __typename?: 'Mutation';
  setGroupToProzess: {
    __typename?: 'SetGroupToProzessPayload';
    userFacingProzess?:
      | {__typename?: 'Prozess'; id: string; name: string}
      | {__typename?: 'ServicePortalLink'; id: string; name: string}
      | null;
  };
};

export const ProzessUebersichtDocument = gql`
  query ProzessUebersicht($groupId: UUID, $search: String) {
    my {
      id
      ... on BackofficeUser {
        kommune {
          prozessGroups {
            id
            name
          }
          prozesseUndLeistungen(groupId: $groupId, search: $search)
            @export(exportName: "UserFacingProzess") {
            id
            name
            group @export(exportName: "ProzessGroup") {
              id
              name
            }
            ... on Prozess {
              tags {
                tag
              }
              versionenCount
              versionen {
                id
              }
              currentVersion {
                id
                publishedAt
              }
            }
            ... on ServicePortalLink {
              url
            }
          }
        }
      }
    }
  }
`;

export function useProzessUebersichtQuery(
  options?: Omit<Urql.UseQueryArgs<ProzessUebersichtQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ProzessUebersichtQuery, ProzessUebersichtQueryVariables>(
    {query: ProzessUebersichtDocument, ...options},
  );
}
export const SetGroupToProzessDocument = gql`
  mutation setGroupToProzess($groupId: UUID, $prozessId: UUID!) {
    setGroupToProzess(input: {groupId: $groupId, prozessId: $prozessId}) {
      userFacingProzess {
        id
        name
      }
    }
  }
`;

export function useSetGroupToProzessMutation() {
  return Urql.useMutation<
    SetGroupToProzessMutation,
    SetGroupToProzessMutationVariables
  >(SetGroupToProzessDocument);
}
