import * as Dialog from '@radix-ui/react-dialog';
import {useState} from 'react';
import SVG from 'react-inlinesvg';
import {toast} from 'react-toastify';
import {Modal} from 'src/components/modal/modal';
import {Button} from 'src/components/ui/button';
import Delete from '../../../../assets/delete.svg';
import {useBackofficeUserDeleteMutation} from './actions.generated';

export const NutzendenLoeschen = ({
  userId,
  userName,
  disabled,
}: {
  userId: string;
  userName: string;
  disabled: boolean;
}) => {
  const [, deleteUser] = useBackofficeUserDeleteMutation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild disabled={disabled}>
        <Button variant='text' disabled={disabled} type='button'>
          <SVG src={Delete} className='fill-gray-900' />
        </Button>
      </Dialog.Trigger>
      <Modal title='Nutzenden löschen' className='w-fit'>
        <p className='mb-4 font-bold'>
          Sind Sie sicher, dass Sie {userName} löschen möchten?
        </p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden</p>
        <div className='mt-12 flex w-full justify-between gap-14'>
          <Button
            className='mt-3 min-w-48 self-center'
            variant='destructive'
            onClick={async () => {
              const result = await deleteUser({input: {userId}});
              if (result.error) {
                toast.error(`${userName} konnte nicht gelöscht werden`);
                return;
              }
              toast.success(`${userName} gelöscht`);
              setIsOpen(false);
            }}
          >
            Löschen
          </Button>
          <Dialog.DialogClose asChild>
            <Button
              type='button'
              className='mt-3 min-w-48 self-center'
              variant='outlined'
            >
              Abbrechen
            </Button>
          </Dialog.DialogClose>
        </div>
      </Modal>
    </Dialog.Root>
  );
};
