import {CaptureConsole, ReportingObserver} from '@sentry/integrations';
import {
  init as sentryInit,
  BrowserTracing,
  ErrorBoundary as SentryErrorBoundary,
  reactRouterV6Instrumentation,
} from '@sentry/react';
import {useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import generatedGitInfo from './generatedGitInfo.json';
import {AuthProvider} from './lib/context/auth/auth.tsx';
import {router} from './router.tsx';
import {UrqlProvider} from './urql.tsx';
import 'react-toastify/dist/ReactToastify.css';

sentryInit({
  dsn: import.meta.env.DEV
    ? undefined
    : 'https://9baae6812acb4f548c6b9796936013aa@sentry.atmina.systems/21',
  enableTracing: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new CaptureConsole(),
    new ReportingObserver(),
  ],
  environment: generatedGitInfo.environment,
  release: generatedGitInfo.release,
  beforeSend(event) {
    // Ignore hot update messages from vite, read by CaptureConsole
    if (event.message?.includes('[vite]')) return null;
    return event;
  },
});

const App = () => (
  <SentryErrorBoundary>
    <HelmetProvider>
      <AuthProvider>
        <UrqlProvider navigate={router.navigate}>
          <Helmet>
            <title>Kommunal App Bereich A</title>
          </Helmet>
          <RouterProvider router={router} />
        </UrqlProvider>
      </AuthProvider>
    </HelmetProvider>
  </SentryErrorBoundary>
);

export default App;
