import {type FC, useRef} from 'react';
import {useClickAway} from 'react-use';
import {assertUnreachable} from '../../../../utils/assert-unreachable';
import {type DistributiveOmit} from '../../../../utils/common-types';
import {
  type SchrittDaten,
  type VerbindungDaten,
} from '../lib/store/prozess-aenderungen.ts';
import {type ProzessEditorSchritt} from '../prozess-version-erstellen.generated.ts';

const schritteOptions: {
  label: string;
  typeName: ProzessEditorSchritt['__typename'];
}[] = [
  {label: 'Bezahlen-Schritt', typeName: 'BezahlenSchritt'},
  {
    label: 'Email-Weiterleiten-Schritt',
    typeName: 'EmailForwarderExportSchritt',
  },
  {label: 'Ende-Schritt', typeName: 'EndeSchritt'},
  {label: 'Formular-Schritt', typeName: 'FormularSchritt'},
  {label: 'Programmatischer Schritt', typeName: 'ProgrammatischerSchritt'},
  {
    label: 'Warte-auf-Bezahlung-abgeschlossen-Schritt',
    typeName: 'WarteAufBezahlungAbgeschlossenSchritt',
  },
];

export type NeuerSchrittSelectProps = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  onSelect: (
    label: string,
    typeName: ProzessEditorSchritt['__typename'],
    top?: number,
    left?: number,
    right?: number,
    bottom?: number,
  ) => void;
  onClickAway: () => void;
};

export const NeuerSchrittSelect: FC<NeuerSchrittSelectProps> = ({
  top,
  left,
  right,
  bottom,
  onSelect,
  onClickAway,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, onClickAway);

  return (
    <div
      className='absolute z-10 flex flex-col gap-2 rounded-md border border-gray-300 bg-white py-2'
      style={{top, left, right, bottom}}
      ref={ref}
    >
      {schritteOptions.map(({label, typeName}) => (
        <div
          className='px-4 hover:cursor-pointer hover:bg-gray-100'
          onClick={() => onSelect(label, typeName, top, left, right, bottom)}
          key={typeName}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export const newSchrittByType = ({
  titel: schrittLabel,
  __typename,
}: Pick<SchrittDaten, '__typename' | 'titel'>): DistributiveOmit<
  SchrittDaten,
  'id'
> => {
  const commonFields = {
    titel: `Neuer ${schrittLabel}`,
    erledigungDurchBuerger: false,
    verbindungen: [] as VerbindungDaten[],
    slots: [],
    mitarbeiterGruppe: null,
  };

  switch (__typename) {
    case 'BezahlenSchritt': {
      return {
        ...commonFields,
        __typename,
        betrag: 0,
      };
    }
    case 'EmailForwarderExportSchritt': {
      return {
        ...commonFields,
        __typename,
        body: '',
        emailAddress: '',
        subject: '',
      };
    }
    case 'FormularSchritt': {
      return {
        ...commonFields,
        __typename,
        felder: [],
      };
    }
    case 'EndeSchritt': {
      return {
        ...commonFields,
        __typename,
      };
    }
    case 'ProgrammatischerSchritt': {
      return {
        ...commonFields,
        __typename,
        implementationAssemblyQualifiedName: '',
      };
    }
    case 'WarteAufBezahlungAbgeschlossenSchritt': {
      return {
        ...commonFields,
        __typename,
        bezahlenSchrittId: '',
      };
    }
    case 'GovManagerRestExportSchritt':
      return {
        ...commonFields,
        __typename,
        exportFilePartsAsPdf: false,
        vorgangsSchluessel: '',
        mandantenSchluessel: '',
        vorgangsTyp: '',
        exportParts: [],
      };
    case 'WarteAufGovManagerSchritt':
      return {
        ...commonFields,
        __typename,
        govManagerSchrittId: '',
      };
    default: {
      return assertUnreachable(__typename);
    }
  }
};
