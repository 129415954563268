import {type FormBuilder, useFormBuilder} from '@atmina/formbuilder';
import {useCallback, useEffect} from 'react';
import {MdAddCircleOutline} from 'react-icons/md';
import {RiDeleteBin6Line} from 'react-icons/ri';
import {
  CheckboxFormField,
  SimpleCheckBoxFormField,
} from 'src/components/form/fields/checkbox-field/checkbox-field.tsx';
import {InputFormField} from 'src/components/form/fields/input-field/input-field.tsx';
import {Button} from 'src/components/ui/button/button.tsx';
import {
  dotPathToExportParts,
  getDefaultValuesFromExportParts,
} from '../../lib/gov-manager-export-converter.ts';
import {useProzessEditorStore} from '../../lib/store';
import {
  type SchrittDaten,
  type StoreProzess,
} from '../../lib/store/prozess-aenderungen.ts';
import {type ProzessEditorSchritt_GovManagerRestExportSchritt} from '../../prozess-version-erstellen.generated.ts';
import {FormCard} from './form-card.tsx';

export type JsonSlotFormFieldProps = {
  exportField: boolean;
  path: string;
};

export const JsonSlotFormField = ({
  on,
  label,
}: {
  on: FormBuilder<JsonSlotFormFieldProps>;
  label: string;
}) => {
  const exportField = on.exportField.$useWatch();

  return (
    <div>
      <SimpleCheckBoxFormField on={on.exportField} label={label} />{' '}
      {exportField && <InputFormField on={on.path} label='Export Pfad' />}
    </div>
  );
};

export type JsonStringFormFieldProps = {
  path: string;
  value: string;
};
export const JsonStringFormField = ({
  on,
}: {
  on: FormBuilder<JsonStringFormFieldProps>;
}) => (
  <div className='w-full'>
    <InputFormField on={on.value} label='Wert' />
    <InputFormField on={on.path} label='Export Pfad' />
  </div>
);

export type GovManagerRestExportSchritt = Extract<
  SchrittDaten,
  {__typename: 'GovManagerRestExportSchritt'}
>;

export const GovManagerRestExportSchritt = ({
  on,
  subFormIsValid,
  storeProzess,
}: {
  on: FormBuilder<GovManagerRestExportSchritt>;
  subFormIsValid: (value: boolean) => void;
  storeProzess: StoreProzess;
}) => {
  const currentExportParts = on.exportParts.$useWatch();
  const {selectedSchritt} = useProzessEditorStore();
  const allSlots = useCallback(
    () => storeProzess.schritte.flatMap((schritt) => schritt.slots),
    [storeProzess],
  );
  const allSlotsNames = storeProzess.schritte.flatMap((schritt) =>
    schritt.slots.map((slot) => slot.name),
  );
  const {defaultJsonsSlotFormFields, defaultJsonStringFormFields} =
    getDefaultValuesFromExportParts(allSlotsNames, currentExportParts);

  const govManagerBuilder = useFormBuilder<{
    exportFilePartsAsPdf: ProzessEditorSchritt_GovManagerRestExportSchritt['exportFilePartsAsPdf'];
    jsonSlotFormFields: Record<string, JsonSlotFormFieldProps>;
    JsonStringFormFields: JsonStringFormFieldProps[];
  }>({
    defaultValues: {
      jsonSlotFormFields: defaultJsonsSlotFormFields,
      JsonStringFormFields: defaultJsonStringFormFields,
      exportFilePartsAsPdf:
        selectedSchritt?.__typename === 'GovManagerRestExportSchritt'
          ? selectedSchritt.exportFilePartsAsPdf
          : false,
    },
  });

  const {formState, fields, clearErrors} = govManagerBuilder;

  const nextJsonSlotFormFields =
    govManagerBuilder.fields.jsonSlotFormFields.$useWatch();
  const nextStringFormFields =
    govManagerBuilder.fields.JsonStringFormFields.$useWatch();

  const jsonStringFromFieldArray =
    govManagerBuilder.fields.JsonStringFormFields.$useFieldArray();
  useEffect(() => {
    clearErrors();
    const allPaths = Object.values(nextJsonSlotFormFields).map(
      ({path}) => path,
    );

    Object.entries(nextJsonSlotFormFields)
      .filter(([, value]) => value.exportField)
      .forEach(([key, value]) => {
        if (value.exportField && !value.path) {
          fields.jsonSlotFormFields[key].$setError(
            {message: `Bitte geben Sie einen Pfad an.`},
            {shouldFocus: false},
          );
          return;
        }

        const badPathRegex = /^\.|\.$|\.\./;
        if (badPathRegex.test(value.path)) {
          fields.jsonSlotFormFields[key].$setError(
            {message: `Bitte geben Sie einen gültigen Pfad an`},
            {shouldFocus: false},
          );
          return;
        }

        const isDuplicate =
          allPaths.filter((path) => path === value.path).length !== 1;
        if (isDuplicate) {
          fields.jsonSlotFormFields[key].$setError(
            {message: 'Gleicher Pfad bereits unter anderem Feld vorhanden.'},
            {shouldFocus: false},
          );
          return;
        }
      });

    const nextParts = dotPathToExportParts(
      allSlots(),
      nextJsonSlotFormFields,
      nextStringFormFields,
    );
    on.exportParts.$setValue(nextParts);
  }, [
    allSlots,
    nextJsonSlotFormFields,
    nextStringFormFields,
    fields,
    on.exportParts,
    clearErrors,
  ]);

  useEffect(() => {
    const hasErrors = Object.keys(formState.errors).length !== 0;
    subFormIsValid(!hasErrors);
  }, [formState, subFormIsValid]);

  return (
    <div className='space-y-4'>
      <div className='flex items-center gap-4 font-bold'>
        <h2 className='whitespace-nowrap'>Export Felder</h2>
        <hr className='ml-auto h-px w-full' />
      </div>
      <FormCard heading='Optionen' key={selectedSchritt?.id}>
        <CheckboxFormField
          on={on.exportFilePartsAsPdf}
          label='Bürger Uploads in PDF-Format umwandeln'
        />
      </FormCard>
      <div className='space-y-4'>
        {storeProzess.schritte
          .filter(
            (schritt) =>
              schritt.slots.length !== 0 &&
              schritt.__typename != 'GovManagerRestExportSchritt',
          )
          .sort((a, b) => (a.titel ?? '').localeCompare(b.titel ?? ''))
          .map((schritt) => (
            <FormCard heading={schritt.titel} key={schritt.id}>
              {schritt.slots &&
                schritt.slots.map((slot) => {
                  const feld =
                    schritt.__typename === 'FormularSchritt'
                      ? schritt.felder.find(
                          (feld) => feld.slotName === slot.name,
                        )
                      : undefined;

                  return (
                    <JsonSlotFormField
                      key={slot.name}
                      label={feld?.angezeigterName ?? slot.name}
                      on={
                        govManagerBuilder.fields.jsonSlotFormFields[slot.name]
                      }
                    />
                  );
                })}
            </FormCard>
          ))}
        <FormCard heading='Zusätzliche Werte' className='space-y-4'>
          {jsonStringFromFieldArray.fields.map((jsonStringField, index) => (
            <div className='flex w-full gap-4' key={jsonStringField.$key}>
              <JsonStringFormField on={jsonStringField} />
              <button
                type='button'
                onClick={() => jsonStringFromFieldArray.remove(index)}
              >
                <RiDeleteBin6Line className='size-6 text-red-500' />
              </button>
            </div>
          ))}

          <Button
            onClick={() => {
              jsonStringFromFieldArray.append({value: '', path: ''});
            }}
            variant='solid'
            type='button'
            className=' flex w-full items-center justify-center gap-2 bg-gray-300 text-gray-600'
          >
            <MdAddCircleOutline className='size-5' />
            Hinzufügen
          </Button>
        </FormCard>
      </div>
    </div>
  );
};
